import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = ["body"];
  static values = { dark: String, light: String, userTheme: String };

  connect() {
    const controller = this;

    if (this.userThemeValue?.length > 0) {
      switch (this.userThemeValue) {
        case "dark":
          controller.bodyTarget.dataset.theme = controller.darkValue;
          break;
        case "light":
          controller.bodyTarget.dataset.theme = controller.lightValue;
          break;
      }

      return;
    }

    this.defaultColorMode((isDarkMode) => {
      if (isDarkMode) {
        controller.bodyTarget.dataset.theme = controller.darkValue;
      } else {
        controller.bodyTarget.dataset.theme = controller.lightValue;
      }
    });
  }

  toggle() {
    if (this.bodyTarget.dataset.theme === this.darkValue) {
      this.bodyTarget.dataset.theme = this.lightValue;
      put("/profile/theme", { body: { theme: "light" } });
    } else {
      this.bodyTarget.dataset.theme = this.darkValue;
      put("/profile/theme", { body: { theme: "dark" } });
    }
  }

  defaultColorMode(fn) {
    if (!window.matchMedia) {
      return;
    }

    const query = window.matchMedia("(prefers-color-scheme: dark)");

    fn(query.matches);

    query.addEventListener("change", (event) => fn(event.matches));
  }
}
