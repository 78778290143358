import { Controller } from "@hotwired/stimulus";
import { get, post, put, patch, destroy } from "@rails/request.js";

// Connects to data-controller="request"
export default class extends Controller {
  static values = {
    url: String,
    method: String,
  };

  connect() {
    this.methodMap = {
      get: get,
      post: post,
      put: put,
      patch: patch,
      destroy: destroy,
    };
  }

  async do() {
    const method = this.methodValue.toLowerCase();
    if (this.methodMap[method]) {
      await this.methodMap[method](this.urlValue);
    }
  }
}
