import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["target"];

  connect() {
    this.off = true;
  }

  do() {
    if (this.off) {
      this.targetTargets.forEach((target) => {
        console.log(target.dataset);
        if (target.dataset.class) {
          let classes = target.dataset.class.split(" ");
          target.classList.add(...classes);
        } else if (target.dataset.addClass) {
          target.classList.add(...target.dataset.addClass.split(" "));
          target.classList.remove(...target.dataset.removeClass.split(" "));
        }
      });
      this.off = false;
    } else {
      this.targetTargets.forEach((target) => {
        if (target.dataset.class) {
          let classes = target.dataset.class.split(" ");
          target.classList.remove(...classes);
        } else if (target.dataset.addClass) {
          target.classList.remove(...target.dataset.addClass.split(" "));
          target.classList.add(...target.dataset.removeClass.split(" "));
        }
      });
      this.off = true;
    }
  }
}
