import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove("opacity-0", "-translate-y-full");
      this.element.classList.add("opacity-100", "translate-y-0");
    }, 100);
    setTimeout(() => {
      this.element.classList.remove("opacity-100", "translate-y-0");
      this.element.classList.add("opacity-0", "-translate-y-full");
    }, 3000);
  }
}
