import { Controller } from "@hotwired/stimulus";
import { Tabs } from "tailwindcss-stimulus-components";

// Connects to data-controller="tabs"
export default class extends Tabs {
  initialize() {
    if (this.updateAnchorValue && this.anchor)
      this.indexValue = this.tabTargets.findIndex((tab) => tab.dataset.id === this.anchor);
  }

  // Changes to the clicked tab
  change(event) {
    if (event.currentTarget.tagName === "SELECT") {
      this.indexValue = event.currentTarget.selectedIndex;

      // If target specifies an index, use that
    } else if (event.currentTarget.dataset.index) {
      this.indexValue = event.currentTarget.dataset.index;

      // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.indexValue = this.tabTargets.findIndex((tab) => tab.dataset.id == event.currentTarget.dataset.id);

      // Otherwise, use the index of the current target
    } else {
      this.indexValue = this.tabTargets.indexOf(event.currentTarget);
    }
  }

  indexValueChanged() {
    this.showTab();
    this.dispatch("tab-change", {
      target: this.tabTargets[this.indexValue],
      detail: {
        activeIndex: this.indexValue,
      },
    });
    // Update URL with the tab ID if it has one
    // This will be automatically selected on page load
    if (this.updateAnchorValue) {
      const new_tab_id = this.tabTargets[this.indexValue].dataset.id; // Grab the id from the newly activated tab
      if (this.scrollToAnchorValue) {
        location.hash = new_tab_id; // Use location.hash to change the URL with scrolling
      } else {
        const currentUrl = window.location.href; // Get the current URL
        const newUrl = currentUrl.split("#")[0] + "#" + new_tab_id; // Create a new URL with the updated ID
        if (typeof Turbo !== "undefined") {
          Turbo.navigator.history.replace(new URL(newUrl));
        } else {
          history.replaceState({}, document.title, newUrl); // Use history.replaceState to change the URL without scrolling
        }
      }
    }
  }
}
