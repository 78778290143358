import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static values = {
    id: String,
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
    this.highlight = this.highlight.bind(this);
  }

  connect() {
    this.scrollTimeout = setTimeout(this.scroll, 200);
    this.highlightTimeout = setTimeout(this.highlight, 200);
  }

  disconnect() {
    clearTimeout(this.scrollTimeout);
    clearTimeout(this.highlightTimeout);
  }

  highlight() {
    if (!this.idValue) {
      return;
    }

    const target = document.getElementById(this.idValue);

    if (!target) {
      return;
    }

    console.log("highlighting");
    target.classList.add("animate-pulse");

    this.highlightTimeout = setTimeout(() => {
      target.classList.remove("animate-pulse");
    }, 6000);
  }

  scroll() {
    if (!this.idValue) {
      return;
    }

    const target = document.getElementById(this.idValue);

    if (!target) {
      return;
    }

    console.log("scrolling");
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}
