import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="visit"
export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {}

  do() {
    Turbo.visit(this.urlValue);
  }
}
