import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="share"
export default class extends Controller {
  async do() {
    let resp = await post("/family/invitations");
    const shareData = JSON.parse(await resp.text);

    await navigator.share(shareData);
  }
}
